<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active">關於計畫</li>
              <li class="breadcrumb-item active" aria-current="page">
                計畫簡介(英文) Project Introduction
              </li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold"
                  >計畫簡介(英文) Project Introduction</span
                >
              </h2>
            </div>
            <p class="lh-lg font-1-2em">
              Tungnan University and the National Taiwan Library are
              collaborating on the execution of the Ministry of Education's
              grant for digital humanities projects for social education
              institutions. In this year's project, the project team is
              leveraging the National Taiwan Library's publication “Taiwan
              Indigenous Tourism Journals to Japan” as the foundation to develop
              digital educational materials, interactive experiential learning
              content, and related derivative designs. The project team not only
              utilizes the National Taiwan Library as a practical field for the
              application and manifestation of knowledge in the humanities and
              social sciences, but also integrates Tungnan University's
              expertise in digital technology, showcasing our outstanding
              innovation in the humanities. Subsequent teaching demonstrations
              and promotions will be conducted in partnership with Jhangshu
              International Creative Technical High School.
            </p>
            <p class="lh-lg font-1-2em">
              Taiwan Indigenous Tourism Journals to Japan was compiled by Fujine
              Yoshiharu, a technician under the employment of the
              Government-General of Taiwan, and provides a detailed account of
              the itinerary for the first visit of Taiwan's indigenous people to
              Japan arranged by the Government-General of Taiwan in August 1897.
              The book includes background information on the 10 indigenous
              participants, observations recorded during their 29-day journey,
              and Fujine Yoshiharu's post-event reflections and recommendations.
              Through this visit report, readers not only gain a sense of the
              amazement and bewilderment of Taiwan's indigenous people as they
              set foot on Japanese soil, encountering foreign customs and novel
              experiences, but also explore the differences in ethnic
              consciousness, lifestyles, and cultural perspectives between
              Taiwan's indigenous people and the Japanese colonial rulers
              through Fujine's descriptions. These differences are vividly
              portrayed through their dialogues and behaviors, making it a
              significant and thought-provoking aspect of the book.
            </p>
            <p class="lh-lg font-1-2em">
              Here is an overview of their itinerary: On Aug 2, Indigenous
              participants gathered in Taipei and proceeded to the Office of the
              Governor-General of Taiwan. Deputy Governor-General Tatsufumi
              Naofumi explained the purpose of their journey and provided
              instructions. On Aug 3, The delegation departed from Taipei and
              took a train to Keelung. On the same day, they boarded the ship
              “Fuzan Maru” bound for Japan. After several days of travel at sea,
              the delegation arrived at their first destination, Nagasaki, on
              Aug 6. They had a brief tour of factories before continuing their
              journey north. On Aug 7, The delegation passed through Moji and
              entered the Seto Naikai. On the 8th, the ship sailed to Ujina (now
              part of Hiroshima), where they had the opportunity to witness
              Japan's military facilities and logistical supplies. On Aug 9, the
              delegation arrived in Kobe, where they visited the Kawasaki
              Shipyard and the Minatogawa Shrine. They observed the orderly
              cityscape and clean streets of Japan. On Aug 10, the delegation
              took a train from Kobe to Nagoya. During the journey, they
              observed farm operations in Japan and various crops, and also
              visited a local textile factory. On Aug 11, the delegation reached
              its final destination, Tokyo, and remained there until Aug 21.
              During their stay in Tokyo, the delegation engaged in various
              activities and visits, including visiting the Imperial Palace and
              Nijubashi, hiking up Mount Atago to catch a panoramic view of
              Tokyo, paying a visit to the Imperial Hotel, where they met with
              the Taiwan Governor-General, Nogi Maresuke, and explored the
              hotel's facilities and gardens. They also had the opportunity to
              bathe in the hotel's baths and wear tailor-made traditional
              Japanese kimonos. Additionally, they visited Ueno Park, museums,
              and the zoo, and they also explored the local market activities in
              Asakusa, where they enjoyed watching revolving displays and
              acrobatic performances. Furthermore, the delegation visited the
              Kanegafuchi Spinning Company, Ryounkaku, paid their respects at
              Yasukuni Shrine, and toured Yushukan Museum. Afterward, there were
              special arrangements to visit the Aoyama Military Training Ground,
              Imperial Japanese Army Tokyo Arsenal, and Shooting School to
              observe military exercises and artillery firing. They also visited
              the Tokyo Ordnance Depot in the Shiba-ku (Tokyo Naval Arsenal),
              Yokosuka Shipyard, and the warships inside the facility. On August
              21, the delegation officially left Tokyo and traveled to Osaka. On
              Aug 22, the delegation made an impromptu stop at Hikone Station to
              welcome the Japanese Emperor's train, allowing them to witness the
              Emperor in person. The group then stayed in Osaka for two days,
              visiting Osaka Castle, the Osaka Mint Bureau, and the Osaka
              Arsenal. On August 24, after visiting Tennoji and the Asahi
              Shimbun’s printing facilities, they hurried to Kobe in the
              afternoon and boarded the Yorihime Maru, which departed on the
              same day, returning to Taiwan.
            </p>
            <p class="lh-lg font-1-2em">
              Based on the itinerary described in the previous historical
              records, this project combines expertise from the fields of
              history, geography, and literature to collect data and explore
              content. The goal is to reconstruct the image and background of
              Taiwan's indigenous people in the late 19th century as accurately
              as possible, such as by integrating key topics from this
              historical context to extract engaging and meaningful dialogues
              and perspectives. Furthermore, the project aims to develop
              educational materials, quiz banks, and teaching aids suitable for
              12-year basic education that not only respond to the cross-era
              demands of the United Nations' 17 Sustainable Development Goals
              (SDGs) but also make learning interesting, fun, and engaging.
              Therefore, we have titled this project “Dialogues at the Turn of
              the Century: Taiwanese Indigenous Peoples' First Experience of
              Imperial Japan.” The project consists of five animated short film
              episodes, employing character creation and scene construction. The
              project also includes the development of relevant background
              knowledge, storyline, and learning materials to expand and
              invigorate educational applications. Furthermore, the development
              of VR and AR educational tools is employed to enhance the
              engagement of students, aiming to stimulate their interest in
              Taiwanese cultural and historical contexts. This, in turn, is
              envisaged to foster a continued interest and exploration of
              learning.
            </p>
            <table class="table table-bordered border-dark caption-top">
              <caption>
                專有名詞對照表
              </caption>
              <thead>
                <tr class="table-warning border-dark">
                  <th width="40%">專有名詞</th>
                  <th width="60%">英文標示</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>東南科技大學</td>
                  <td>Tungnan University</td>
                </tr>
                <tr>
                  <td>國立臺灣圖書館</td>
                  <td>National Taiwan Library</td>
                </tr>
                <tr>
                  <td>《蕃人觀光日誌》</td>
                  <td>《Taiwan Indigenous Tourism Journals to Japan》</td>
                </tr>
                <tr>
                  <td>樟樹高中</td>
                  <td>Jhangshu International Creative Technical High School</td>
                </tr>
              </tbody>
            </table>
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-brands fa-fw fa-youtube me-3 font-1-5em text-danger"
                ></i
                ><span class="text-dark font-1-2em fw-bold">動畫解說</span>
              </h2>
            </div>
            <div class="w-100 loading" ref="target5">
              <iframe
                width="100%"
                height="700"
                src="https://www.youtube.com/embed/ZY3zW582tLk?si=1vGOnlcCJ3sJJMR9"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
